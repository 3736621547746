import { useFetch as fetch } from '@vueuse/core/index'
import store from '@/store/index'
import Cookie from 'cookie-universal'
import Const from '@/plugins/constanst'

const cookies = Cookie()
const { API_URL } = Const

export default {
  install: (app, options) => {
    const useFetch = async (url, optionsApi = {}) => {
      const { router } = options
      const token = cookies.get('token')
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...optionsApi.headers,
      }

      const urlApi = optionsApi.isFullUrl ? url : `${API_URL}${url}`
      const opt = {
        headers: optionsApi.noHeader ? {} : { ...headers },
        method: optionsApi.method || 'get',
      }

      if (optionsApi.body) {
        opt.body = optionsApi.body
      }

      const { data, error } = await fetch(urlApi, opt)

      if (error.value) {
        if (error.value == 'Unauthorized') {
          await store.dispatch('refreshToken')
        }

        const token = cookies.get('token')

        if (!token) {
          router.replace('/login')
        }
      }

      return { data, error }
    }

    app.provide('useFetch', useFetch)
  },
}
