export default ($api) => ({
  getTokenImageKit(params) {
    return $api.post('/auth/imagekit', {
      body: params,
    })
  },
  uploadImageToKit(params) {
    return $api.post('https://upload.imagekit.io/api/v2/files/upload', {
      body: params,
      isFullUrl: true,
      noHeader: true,
    })
  },
  uploadImageToBe(id, params) {
    return $api.put(`/transactions/${id}/update-receipt`, {
      body: params,
    })
  },
})
