export const logo = [
  '556 134',
  `
  <title>muabantraodoi vue</title>
  <g><path style="opacity:1" fill="#dcbdac" d="M 59.5,2.5 C 58.1667,2.5 56.8333,2.5 55.5,2.5C 53.6965,1.83798 51.6965,1.50465 49.5,1.5C 45.8832,1.39809 42.5499,2.06476 39.5,3.5C 36.4261,3.87256 33.4261,4.53922 30.5,5.5C 29.4937,5.06233 29.4937,4.39567 30.5,3.5C 33.3704,2.36639 36.3704,1.69973 39.5,1.5C 45.783,0.415565 52.1163,0.248898 58.5,1C 59.056,1.38258 59.3893,1.88258 59.5,2.5 Z"/></g>
<g><path style="opacity:0.137" fill="#fbece5" d="M 55.5,2.5 C 50.1463,2.56884 44.813,2.90218 39.5,3.5C 42.5499,2.06476 45.8832,1.39809 49.5,1.5C 51.6965,1.50465 53.6965,1.83798 55.5,2.5 Z"/></g>
<g><path style="opacity:0.973" fill="#ecd0ba" d="M 40.5,-0.5 C 46.8333,-0.5 53.1667,-0.5 59.5,-0.5C 65.7949,2.00474 72.1282,4.83807 78.5,8C 81.449,10.7757 84.1156,13.609 86.5,16.5C 85.8333,16.5 85.5,16.8333 85.5,17.5C 78.3466,10.0423 69.6799,5.04228 59.5,2.5C 59.3893,1.88258 59.056,1.38258 58.5,1C 52.1163,0.248898 45.783,0.415565 39.5,1.5C 39.8333,0.833333 40.1667,0.166667 40.5,-0.5 Z"/></g>
<g><path style="opacity:0.906" fill="#eacfb5" d="M 30.5,3.5 C 29.4937,4.39567 29.4937,5.06233 30.5,5.5C 25.5784,8.41311 20.9117,11.7464 16.5,15.5C 15.6618,15.1583 15.3284,14.4916 15.5,13.5C 19.406,8.60564 24.406,5.2723 30.5,3.5 Z"/></g>
<g><path style="opacity:0.035" fill="#fbefe2" d="M 38.5,10.5 C 38.2648,9.90326 37.7648,9.56993 37,9.5C 34.9534,10.6094 32.7867,11.2761 30.5,11.5C 32.6076,9.94094 34.9409,8.60761 37.5,7.5C 39.4041,8.2566 39.7375,9.2566 38.5,10.5 Z"/></g>
<g><path style="opacity:1" fill="#e9c5a7" d="M 91.5,39.5 C 92.6144,46.1518 92.6144,52.8185 91.5,59.5C 91.5,60.1667 91.5,60.8333 91.5,61.5C 89.9519,61.1788 89.2852,60.1788 89.5,58.5C 92.0386,41.0739 86.3719,26.9072 72.5,16C 67.3457,13.0856 62.0124,10.7522 56.5,9C 57.263,8.05647 58.263,7.55647 59.5,7.5C 76.5642,11.8975 87.2309,22.5642 91.5,39.5 Z"/></g>
<g><path style="opacity:0.451" fill="#fdcda7" d="M 61.5,16.5 C 55.361,16.8376 49.0276,16.8376 42.5,16.5C 44.8309,15.6827 47.3309,15.0161 50,14.5C 53.7921,15.4375 57.6254,16.1042 61.5,16.5 Z"/></g>
<g><path style="opacity:0.031" fill="#ffd3ba" d="M 68.5,18.5 C 68.1667,18.5 67.8333,18.5 67.5,18.5C 66.0101,16.7202 64.0101,16.0535 61.5,16.5C 64.6234,13.8792 66.9567,14.5458 68.5,18.5 Z"/></g>
<g><path style="opacity:1" fill="#e4894a" d="M 42.5,16.5 C 49.0276,16.8376 55.361,16.8376 61.5,16.5C 64.0101,16.0535 66.0101,16.7202 67.5,18.5C 67.7852,20.288 67.4519,21.9547 66.5,23.5C 65.8933,23.3764 65.56,23.0431 65.5,22.5C 66.9997,20.4909 66.4997,19.1576 64,18.5C 60.9484,19.5788 60.7817,20.9122 63.5,22.5C 62.4061,24.8362 61.0728,24.8362 59.5,22.5C 59.5,21.5 59.5,20.5 59.5,19.5C 58.5,19.5 57.5,19.5 56.5,19.5C 52.1667,19.5 47.8333,19.5 43.5,19.5C 41.1016,19.2594 39.1016,19.9261 37.5,21.5C 35.8212,21.7148 34.8212,21.0481 34.5,19.5C 36.6845,17.4152 39.3511,16.4152 42.5,16.5 Z"/></g>
<g><path style="opacity:0.165" fill="#ffe0c1" d="M 43.5,19.5 C 41.7857,20.7384 39.7857,21.405 37.5,21.5C 39.1016,19.9261 41.1016,19.2594 43.5,19.5 Z"/></g>
<g><path style="opacity:0.135" fill="#ffe3be" d="M 65.5,22.5 C 64.8333,22.5 64.1667,22.5 63.5,22.5C 60.7817,20.9122 60.9484,19.5788 64,18.5C 66.4997,19.1576 66.9997,20.4909 65.5,22.5 Z"/></g>
<g><path style="opacity:0.961" fill="#e9cfae" d="M 86.5,16.5 C 90.88,20.2341 94.0467,24.9008 96,30.5C 97.9442,34.3182 98.6109,38.3182 98,42.5C 95.8281,33.1395 91.6615,24.8062 85.5,17.5C 85.5,16.8333 85.8333,16.5 86.5,16.5 Z"/></g>
<g><path style="opacity:0.298" fill="#edc9ac" d="M 38.5,10.5 C 28.7682,14.2228 21.1015,20.5561 15.5,29.5C 14.1667,29.5 13.5,28.8333 13.5,27.5C 17.224,20.1095 22.8907,14.7762 30.5,11.5C 32.7867,11.2761 34.9534,10.6094 37,9.5C 37.7648,9.56993 38.2648,9.90326 38.5,10.5 Z"/></g>
<g><path style="opacity:1" fill="#f0b887" d="M 34.5,19.5 C 34.8212,21.0481 35.8212,21.7148 37.5,21.5C 36.4863,22.6737 35.153,23.3404 33.5,23.5C 33.6309,22.6236 33.2975,21.9569 32.5,21.5C 31.3777,22.6302 30.0443,23.2969 28.5,23.5C 29.8021,21.1782 31.8021,19.8449 34.5,19.5 Z"/></g>
<g><path style="opacity:0.09" fill="#ffe7c9" d="M 56.5,19.5 C 57.5,19.5 58.5,19.5 59.5,19.5C 59.5,20.5 59.5,21.5 59.5,22.5C 58.5,21.5 57.5,20.5 56.5,19.5 Z"/></g>
<g><path style="opacity:1" fill="#eaab6e" d="M 67.5,18.5 C 67.8333,18.5 68.1667,18.5 68.5,18.5C 69.6271,24.5007 66.9604,26.6673 60.5,25C 59.7476,24.3292 59.4142,23.4959 59.5,22.5C 61.0728,24.8362 62.4061,24.8362 63.5,22.5C 64.1667,22.5 64.8333,22.5 65.5,22.5C 65.56,23.0431 65.8933,23.3764 66.5,23.5C 67.4519,21.9547 67.7852,20.288 67.5,18.5 Z"/></g>
<g><path style="opacity:0.369" fill="#ffda9e" d="M 28.5,23.5 C 27.5,25.1667 26.1667,26.5 24.5,27.5C 24.8333,25.1667 26.1667,23.8333 28.5,23.5 Z"/></g>
<g><path style="opacity:0.992" fill="#f0daba" d="M 15.5,13.5 C 15.3284,14.4916 15.6618,15.1583 16.5,15.5C 9.64469,22.8752 5.14469,31.5419 3,41.5C 2.01381,42.1712 0.847141,42.1712 -0.5,41.5C -0.5,40.1667 -0.5,38.8333 -0.5,37.5C 2.14786,33.5378 4.31453,29.2045 6,24.5C 8.71175,20.3882 11.8784,16.7216 15.5,13.5 Z"/></g>
<g><path style="opacity:1" fill="#e99c4d" d="M 33.5,23.5 C 31.1918,25.4707 28.8585,27.4707 26.5,29.5C 24.8546,30.4787 23.5213,31.812 22.5,33.5C 21.7025,33.0431 21.3691,32.3764 21.5,31.5C 21.7376,29.5956 22.7376,28.2623 24.5,27.5C 26.1667,26.5 27.5,25.1667 28.5,23.5C 30.0443,23.2969 31.3777,22.6302 32.5,21.5C 33.2975,21.9569 33.6309,22.6236 33.5,23.5 Z"/></g>
<g><path style="opacity:0.592" fill="#fad8bf" d="M 76.5,28.5 C 75.5178,27.8592 74.5178,27.1925 73.5,26.5C 72.7025,26.9569 72.3691,27.6236 72.5,28.5C 70.1569,27.0327 70.4903,25.6994 73.5,24.5C 75.2882,25.2826 76.2882,26.6159 76.5,28.5 Z"/></g>
<g><path style="opacity:1" fill="#d97539" d="M 76.5,28.5 C 77.0283,29.8474 77.195,31.1807 77,32.5C 76.2411,30.9371 75.0744,29.9371 73.5,29.5C 72.8333,29.5 72.5,29.1667 72.5,28.5C 72.3691,27.6236 72.7025,26.9569 73.5,26.5C 74.5178,27.1925 75.5178,27.8592 76.5,28.5 Z"/></g>
<g><path style="opacity:0.796" fill="#f4ddc4" d="M 13.5,27.5 C 13.5,28.8333 14.1667,29.5 15.5,29.5C 11.0648,37.9237 9.3981,46.9237 10.5,56.5C 9.67284,51.0149 9.17284,45.3482 9,39.5C 7.10292,47.5352 7.26958,55.5352 9.5,63.5C 4.67769,50.8817 6.01102,38.8817 13.5,27.5 Z"/></g>
<g><path style="opacity:0.02" fill="#fef8c2" d="M 65.5,32.5 C 55.3194,32.1726 45.3194,32.506 35.5,33.5C 34.5318,35.6074 34.1984,37.9407 34.5,40.5C 31.9136,37.7068 31.9136,34.8735 34.5,32C 45.0083,31.1748 55.3416,31.3415 65.5,32.5 Z"/></g>
<g><path style="opacity:0.824" fill="#f1c97a" d="M 35.5,33.5 C 45.3194,32.506 55.3194,32.1726 65.5,32.5C 66.8333,35.1667 66.8333,37.8333 65.5,40.5C 65.5,40.1667 65.5,39.8333 65.5,39.5C 65.7394,37.5328 65.4061,35.6995 64.5,34C 54.8391,33.5002 45.1724,33.3335 35.5,33.5 Z"/></g>
<g><path style="opacity:1" fill="#f4b081" d="M 76.5,28.5 C 78.8218,29.8021 80.1551,31.8021 80.5,34.5C 78.8041,32.871 77.8041,33.2043 77.5,35.5C 75.8079,33.7952 74.4746,31.7952 73.5,29.5C 75.0744,29.9371 76.2411,30.9371 77,32.5C 77.195,31.1807 77.0283,29.8474 76.5,28.5 Z"/></g>
<g><path style="opacity:1" fill="#f5d194" d="M 26.5,29.5 C 25.0834,32.669 23.4168,35.669 21.5,38.5C 21.8432,36.5168 21.1765,35.1834 19.5,34.5C 19.427,32.9731 20.0937,31.9731 21.5,31.5C 21.3691,32.3764 21.7025,33.0431 22.5,33.5C 23.5213,31.812 24.8546,30.4787 26.5,29.5 Z"/></g>
<g><path style="opacity:1" fill="#f7a910" d="M 35.5,33.5 C 45.1724,33.3335 54.8391,33.5002 64.5,34C 65.4061,35.6995 65.7394,37.5328 65.5,39.5C 61.8333,39.5 58.1667,39.5 54.5,39.5C 57.6248,38.5206 60.9581,38.1872 64.5,38.5C 64.5,37.5 64.5,36.5 64.5,35.5C 61.6887,35.5713 59.022,36.238 56.5,37.5C 49.6877,37.3918 43.0211,36.7251 36.5,35.5C 36.5,36.5 36.5,37.5 36.5,38.5C 39.7131,38.19 42.7131,38.5233 45.5,39.5C 42.1667,39.5 38.8333,39.5 35.5,39.5C 35.5,37.5 35.5,35.5 35.5,33.5 Z"/></g>
<g><path style="opacity:1" fill="#e7ac47" d="M 19.5,34.5 C 21.1765,35.1834 21.8432,36.5168 21.5,38.5C 20.1765,39.8499 19.1765,39.6833 18.5,38C 18.9891,36.8615 19.3225,35.6948 19.5,34.5 Z"/></g>
<g><path style="opacity:1" fill="#fda203" d="M 54.5,39.5 C 52.6921,40.9179 52.0254,42.9179 52.5,45.5C 51.7656,44.1225 51.0989,42.6225 50.5,41C 49.1807,40.805 47.8474,40.9717 46.5,41.5C 46.1667,41.5 45.8333,41.5 45.5,41.5C 45.5,40.8333 45.5,40.1667 45.5,39.5C 42.7131,38.5233 39.7131,38.19 36.5,38.5C 36.5,37.5 36.5,36.5 36.5,35.5C 43.0211,36.7251 49.6877,37.3918 56.5,37.5C 59.022,36.238 61.6887,35.5713 64.5,35.5C 64.5,36.5 64.5,37.5 64.5,38.5C 60.9581,38.1872 57.6248,38.5206 54.5,39.5 Z"/></g>
<g><path style="opacity:1" fill="#f8bc8a" d="M 80.5,34.5 C 81.9313,35.7804 82.5979,37.4471 82.5,39.5C 80.7868,38.3646 80.1201,36.698 80.5,34.5 Z"/></g>
<g><path style="opacity:0.212" fill="#fddfbb" d="M 77.5,35.5 C 79.0739,37.1016 79.7406,39.1016 79.5,41.5C 78.2616,39.7857 77.595,37.7857 77.5,35.5 Z"/></g>
<g><path style="opacity:0.808" fill="#f6e4ae" d="M 19.5,34.5 C 19.3225,35.6948 18.9891,36.8615 18.5,38C 19.1765,39.6833 20.1765,39.8499 21.5,38.5C 21.001,41.3892 19.501,42.0558 17,40.5C 16.4585,37.9175 17.2918,35.9175 19.5,34.5 Z"/></g>
<g><path style="opacity:0.996" fill="#ebbe68" d="M 35.5,33.5 C 35.5,35.5 35.5,37.5 35.5,39.5C 38.8333,39.5 42.1667,39.5 45.5,39.5C 45.5,40.1667 45.5,40.8333 45.5,41.5C 41.6286,41.815 37.9619,41.4816 34.5,40.5C 34.1984,37.9407 34.5318,35.6074 35.5,33.5 Z"/></g>
<g><path style="opacity:1" fill="#fc9604" d="M 52.5,45.5 C 52.5,47.1667 52.5,48.8333 52.5,50.5C 50.5,50.5 48.5,50.5 46.5,50.5C 46.5,47.5 46.5,44.5 46.5,41.5C 47.8474,40.9717 49.1807,40.805 50.5,41C 51.0989,42.6225 51.7656,44.1225 52.5,45.5 Z"/></g>
<g><path style="opacity:1" fill="#eca53c" d="M 54.5,39.5 C 58.1667,39.5 61.8333,39.5 65.5,39.5C 65.5,39.8333 65.5,40.1667 65.5,40.5C 61.8333,40.5 58.1667,40.5 54.5,40.5C 54.5,43.1667 54.5,45.8333 54.5,48.5C 54.5,48.8333 54.5,49.1667 54.5,49.5C 52.0122,51.3878 49.0122,52.0545 45.5,51.5C 45.5,48.5 45.5,45.5 45.5,42.5C 45.5,42.1667 45.5,41.8333 45.5,41.5C 45.8333,41.5 46.1667,41.5 46.5,41.5C 46.5,44.5 46.5,47.5 46.5,50.5C 48.5,50.5 50.5,50.5 52.5,50.5C 52.5,48.8333 52.5,47.1667 52.5,45.5C 52.0254,42.9179 52.6921,40.9179 54.5,39.5 Z"/></g>
<g><path style="opacity:0.78" fill="#f3deb7" d="M 65.5,40.5 C 62.3752,41.4794 59.0419,41.8128 55.5,41.5C 55.5,42.5 55.5,43.5 55.5,44.5C 60.2226,45.0071 64.8893,45.8404 69.5,47C 71.6877,48.9643 71.6877,50.4643 69.5,51.5C 69.3059,47.9952 68.3059,47.6618 66.5,50.5C 66.3893,49.8826 66.056,49.3826 65.5,49C 61.7962,47.6792 58.1295,47.5126 54.5,48.5C 54.5,45.8333 54.5,43.1667 54.5,40.5C 58.1667,40.5 61.8333,40.5 65.5,40.5 Z"/></g>
<g><path style="opacity:1" fill="#ee9c57" d="M 80.5,34.5 C 80.1201,36.698 80.7868,38.3646 82.5,39.5C 83.9352,42.5499 84.6019,45.8832 84.5,49.5C 84.4954,51.6965 84.162,53.6965 83.5,55.5C 83.1667,55.5 82.8333,55.5 82.5,55.5C 81.6618,55.1583 81.3284,54.4916 81.5,53.5C 81.665,50.1501 81.4983,46.8168 81,43.5C 80.7216,42.5842 80.2216,41.9175 79.5,41.5C 79.7406,39.1016 79.0739,37.1016 77.5,35.5C 77.8041,33.2043 78.8041,32.871 80.5,34.5 Z"/></g>
<g><path style="opacity:0.459" fill="#f4cb93" d="M 79.5,41.5 C 80.2216,41.9175 80.7216,42.5842 81,43.5C 81.4983,46.8168 81.665,50.1501 81.5,53.5C 81.1667,53.5 80.8333,53.5 80.5,53.5C 80.4955,49.4659 80.1621,45.4659 79.5,41.5 Z"/></g>
<g><path style="opacity:1" fill="#edc68b" d="M 33.5,50.5 C 34.791,50.2627 35.791,50.596 36.5,51.5C 35.1266,51.3433 33.7932,51.51 32.5,52C 33.056,52.3826 33.3893,52.8826 33.5,53.5C 29.67,53.3731 28.3367,51.7064 29.5,48.5C 30.5832,48.4606 31.5832,48.1272 32.5,47.5C 32.593,48.4719 32.9264,49.4719 33.5,50.5 Z"/></g>
<g><path style="opacity:0.796" fill="#eec09d" d="M 21.5,54.5 C 21.5,53.5 21.5,52.5 21.5,51.5C 21.67,50.1779 21.3366,49.0113 20.5,48C 18.0709,47.2967 15.7375,47.4634 13.5,48.5C 15.4464,45.186 18.1131,44.686 21.5,47C 22.8143,49.6047 22.8143,52.1047 21.5,54.5 Z"/></g>
<g><path style="opacity:0.085" fill="#feefe0" d="M 91.5,39.5 C 93.0186,42.5256 93.6852,45.8589 93.5,49.5C 93.6852,53.1411 93.0186,56.4744 91.5,59.5C 92.6144,52.8185 92.6144,46.1518 91.5,39.5 Z"/></g>
<g><path style="opacity:0.482" fill="#fed2ab" d="M 13.5,48.5 C 13.1658,51.188 13.8325,53.5214 15.5,55.5C 14.5041,55.5858 13.6708,55.2524 13,54.5C 12.2109,52.2884 12.3775,50.2884 13.5,48.5 Z"/></g>
<g><path style="opacity:0.024" fill="#ffeacc" d="M 19.5,49.5 C 19.5674,50.9587 19.2341,52.2921 18.5,53.5C 15.0437,52.4638 14.7104,50.7971 17.5,48.5C 18.1835,48.8627 18.8501,49.1961 19.5,49.5 Z"/></g>
<g><path style="opacity:1" fill="#fae2b9" d="M 45.5,42.5 C 45.5,45.5 45.5,48.5 45.5,51.5C 49.0122,52.0545 52.0122,51.3878 54.5,49.5C 55.7134,50.5747 57.2134,51.2414 59,51.5C 61.4979,51.0828 63.9979,50.7495 66.5,50.5C 62.3744,52.0058 58.041,53.0058 53.5,53.5C 50.8333,53.5 48.1667,53.5 45.5,53.5C 42.3447,53.0104 39.3447,52.3438 36.5,51.5C 39.1667,51.5 41.8333,51.5 44.5,51.5C 44.5,50.1667 44.5,48.8333 44.5,47.5C 41.1262,47.6744 37.7928,48.1744 34.5,49C 33.944,49.3826 33.6107,49.8826 33.5,50.5C 32.9264,49.4719 32.593,48.4719 32.5,47.5C 31.5832,48.1272 30.5832,48.4606 29.5,48.5C 30.0577,47.2101 31.0577,46.3768 32.5,46C 35.5,45.6667 38.5,45.3333 41.5,45C 43.133,44.5204 44.4664,43.6871 45.5,42.5 Z"/></g>
<g><path style="opacity:1" fill="#e47527" d="M 21.5,51.5 C 20.8333,50.8333 20.1667,50.1667 19.5,49.5C 18.8501,49.1961 18.1835,48.8627 17.5,48.5C 14.7104,50.7971 15.0437,52.4638 18.5,53.5C 19.8333,55.5 19.8333,57.5 18.5,59.5C 17.9779,57.7809 16.9779,56.4475 15.5,55.5C 13.8325,53.5214 13.1658,51.188 13.5,48.5C 15.7375,47.4634 18.0709,47.2967 20.5,48C 21.3366,49.0113 21.67,50.1779 21.5,51.5 Z"/></g>
<g><path style="opacity:1" fill="#dba66e" d="M 36.5,51.5 C 39.3447,52.3438 42.3447,53.0104 45.5,53.5C 45.5,53.8333 45.5,54.1667 45.5,54.5C 44.8333,54.5 44.1667,54.5 43.5,54.5C 40.1361,55.559 36.8028,55.2257 33.5,53.5C 33.3893,52.8826 33.056,52.3826 32.5,52C 33.7932,51.51 35.1266,51.3433 36.5,51.5 Z"/></g>
<g><path style="opacity:0.048" fill="#ffe9d1" d="M 43.5,54.5 C 44.1667,54.5 44.8333,54.5 45.5,54.5C 45.5,59.8333 45.5,65.1667 45.5,70.5C 44.7901,65.2683 44.1235,59.9349 43.5,54.5 Z"/></g>
<g><path style="opacity:1" fill="#f97809" d="M 45.5,54.5 C 45.5,54.1667 45.5,53.8333 45.5,53.5C 48.1667,53.5 50.8333,53.5 53.5,53.5C 53.5,58.8333 53.5,64.1667 53.5,69.5C 51.1667,69.5 48.8333,69.5 46.5,69.5C 46.8205,64.3054 46.4872,59.3054 45.5,54.5 Z"/></g>
<g><path style="opacity:1" fill="#ebb25d" d="M 80.5,53.5 C 80.8333,53.5 81.1667,53.5 81.5,53.5C 81.3284,54.4916 81.6618,55.1583 82.5,55.5C 82.3259,57.861 81.9926,60.1944 81.5,62.5C 80.3263,63.5137 79.6596,64.847 79.5,66.5C 79.573,68.0269 78.9063,69.0269 77.5,69.5C 75.5,71.1667 73.5,72.8333 71.5,74.5C 69.7354,73.5386 67.7354,73.2053 65.5,73.5C 67.5,72.8333 69.5,72.1667 71.5,71.5C 76.1275,66.2451 79.1275,60.2451 80.5,53.5 Z"/></g>
<g><path style="opacity:0.141" fill="#ffe1bc" d="M 15.5,55.5 C 16.9779,56.4475 17.9779,57.7809 18.5,59.5C 18.8333,61.5 19.1667,63.5 19.5,65.5C 17.5957,62.4659 16.2623,59.1326 15.5,55.5 Z"/></g>
<g><path style="opacity:1" fill="#e9a257" d="M 19.5,49.5 C 20.1667,50.1667 20.8333,50.8333 21.5,51.5C 21.5,52.5 21.5,53.5 21.5,54.5C 21.0766,55.2646 20.7432,56.0979 20.5,57C 21.4088,59.8946 22.4088,62.7279 23.5,65.5C 23.427,67.0269 24.0937,68.0269 25.5,68.5C 25.8333,70.8333 27.1667,72.1667 29.5,72.5C 29.6309,73.3764 29.2975,74.0431 28.5,74.5C 27.5832,73.8728 26.5832,73.5394 25.5,73.5C 24.8645,71.2054 23.5311,69.5387 21.5,68.5C 20.5987,67.7095 19.9321,66.7095 19.5,65.5C 19.1667,63.5 18.8333,61.5 18.5,59.5C 19.8333,57.5 19.8333,55.5 18.5,53.5C 19.2341,52.2921 19.5674,50.9587 19.5,49.5 Z"/></g>
<g><path style="opacity:0.075" fill="#ffedbc" d="M 82.5,55.5 C 82.8333,55.5 83.1667,55.5 83.5,55.5C 83.8294,58.2282 83.1628,60.5615 81.5,62.5C 81.9926,60.1944 82.3259,57.861 82.5,55.5 Z"/></g>
<g><path style="opacity:0.784" fill="#ebaa70" d="M 69.5,51.5 C 68.653,52.9663 67.3197,53.7996 65.5,54C 62.854,54.4974 60.1873,54.6641 57.5,54.5C 56.5,54.5 55.5,54.5 54.5,54.5C 54.5,59.8333 54.5,65.1667 54.5,70.5C 51.5,71.8333 48.5,71.8333 45.5,70.5C 45.5,65.1667 45.5,59.8333 45.5,54.5C 46.4872,59.3054 46.8205,64.3054 46.5,69.5C 48.8333,69.5 51.1667,69.5 53.5,69.5C 53.5,64.1667 53.5,58.8333 53.5,53.5C 58.041,53.0058 62.3744,52.0058 66.5,50.5C 68.3059,47.6618 69.3059,47.9952 69.5,51.5 Z"/></g>
<g><path style="opacity:0.024" fill="#ffe8d1" d="M 57.5,54.5 C 55.9363,59.728 54.9363,65.0613 54.5,70.5C 54.5,65.1667 54.5,59.8333 54.5,54.5C 55.5,54.5 56.5,54.5 57.5,54.5 Z"/></g>
<g><path style="opacity:0.027" fill="#fff6ce" d="M 81.5,62.5 C 81.7709,64.3797 81.1042,65.713 79.5,66.5C 79.6596,64.847 80.3263,63.5137 81.5,62.5 Z"/></g>
<g><path style="opacity:0.588" fill="#fad8a6" d="M 23.5,65.5 C 24.9063,65.9731 25.573,66.9731 25.5,68.5C 24.0937,68.0269 23.427,67.0269 23.5,65.5 Z"/></g>
<g><path style="opacity:0.031" fill="#faf2da" d="M 89.5,58.5 C 89.2852,60.1788 89.9519,61.1788 91.5,61.5C 90.7795,65.6886 89.1128,69.3552 86.5,72.5C 86.9239,69.646 87.9239,66.9793 89.5,64.5C 89.1445,63.4777 88.4778,62.811 87.5,62.5C 87.9887,61.0048 88.6554,59.6715 89.5,58.5 Z"/></g>
<g><path style="opacity:0.988" fill="#eccfa9" d="M 10.5,56.5 C 11.4768,62.7582 13.8101,68.4248 17.5,73.5C 16.5084,73.3284 15.8417,73.6618 15.5,74.5C 12.2197,71.6116 10.2197,67.9449 9.5,63.5C 7.26958,55.5352 7.10292,47.5352 9,39.5C 9.17284,45.3482 9.67284,51.0149 10.5,56.5 Z"/></g>
<g><path style="opacity:0.898" fill="#fad19d" d="M 21.5,68.5 C 23.5311,69.5387 24.8645,71.2054 25.5,73.5C 23.457,72.4206 22.1237,70.754 21.5,68.5 Z"/></g>
<g><path style="opacity:0.816" fill="#fdcc8b" d="M 25.5,68.5 C 27.1667,69.5 28.5,70.8333 29.5,72.5C 27.1667,72.1667 25.8333,70.8333 25.5,68.5 Z"/></g>
<g><path style="opacity:0.251" fill="#ffe7b2" d="M 71.5,71.5 C 69.5,72.1667 67.5,72.8333 65.5,73.5C 64.8333,73.5 64.1667,73.5 63.5,73.5C 63.6107,72.8826 63.944,72.3826 64.5,72C 66.8098,71.5034 69.1432,71.3367 71.5,71.5 Z"/></g>
<g><path style="opacity:1" fill="#ebd3a4" d="M 87.5,62.5 C 88.4778,62.811 89.1445,63.4777 89.5,64.5C 87.9239,66.9793 86.9239,69.646 86.5,72.5C 84.5,74.8333 82.5,77.1667 80.5,79.5C 78.9826,79.5106 77.8159,80.1772 77,81.5C 76.5357,80.9056 76.369,80.2389 76.5,79.5C 81.4216,74.6596 85.0883,68.993 87.5,62.5 Z"/></g>
<g><path style="opacity:0.929" fill="#e3c4a8" d="M 84.5,85.5 C 83.6618,85.1583 83.3284,84.4916 83.5,83.5C 90.7463,76.348 95.413,67.6814 97.5,57.5C 98.4271,61.269 97.9271,64.9356 96,68.5C 93.1193,74.8682 89.286,80.5349 84.5,85.5 Z"/></g>
<g><path style="opacity:0.933" fill="#ecd0b4" d="M 11.5,77.5 C 11.5,78.8333 10.8333,79.5 9.5,79.5C 5.77652,73.2945 2.44319,66.9612 -0.5,60.5C -0.5,59.8333 -0.5,59.1667 -0.5,58.5C 0.687867,57.3157 1.85453,57.3157 3,58.5C 4.59205,65.464 7.42538,71.7973 11.5,77.5 Z"/></g>
<g><path style="opacity:0.769" fill="#f7e4ab" d="M 63.5,73.5 C 63.1072,76.5133 63.7739,79.1799 65.5,81.5C 61.3295,79.6615 60.6628,76.9948 63.5,73.5 Z"/></g>
<g><path style="opacity:0.396" fill="#fde9b6" d="M 77.5,69.5 C 75.02,72.6237 73.02,75.9571 71.5,79.5C 71.5,77.8333 71.5,76.1667 71.5,74.5C 73.5,72.8333 75.5,71.1667 77.5,69.5 Z"/></g>
<g><path style="opacity:0.678" fill="#f0d7a4" d="M 17.5,73.5 C 20.306,77.1373 23.6393,80.3039 27.5,83C 31.6597,84.9125 35.6597,87.0791 39.5,89.5C 36.3738,90.4189 33.3738,89.9189 30.5,88C 24.6823,84.346 19.6823,79.846 15.5,74.5C 15.8417,73.6618 16.5084,73.3284 17.5,73.5 Z"/></g>
<g><path style="opacity:1" fill="#f1c06a" d="M 29.5,72.5 C 32.1667,74.1667 34.8333,75.8333 37.5,77.5C 37.5,77.8333 37.5,78.1667 37.5,78.5C 36.7611,78.369 36.0944,78.5357 35.5,79C 36.3805,79.7083 37.0472,80.5416 37.5,81.5C 32.8033,79.8225 28.8033,77.1558 25.5,73.5C 26.5832,73.5394 27.5832,73.8728 28.5,74.5C 29.2975,74.0431 29.6309,73.3764 29.5,72.5 Z"/></g>
<g><path style="opacity:0.294" fill="#fde7b8" d="M 37.5,78.5 C 37.5,78.1667 37.5,77.8333 37.5,77.5C 39.403,77.7959 41.0697,78.4625 42.5,79.5C 40.7085,79.634 39.0418,79.3007 37.5,78.5 Z"/></g>
<g><path style="opacity:1" fill="#f0b84d" d="M 63.5,73.5 C 64.1667,73.5 64.8333,73.5 65.5,73.5C 67.7354,73.2053 69.7354,73.5386 71.5,74.5C 71.5,76.1667 71.5,77.8333 71.5,79.5C 70.0804,82.3489 68.0804,83.0156 65.5,81.5C 63.7739,79.1799 63.1072,76.5133 63.5,73.5 Z M 69.5,76.5 C 69.0269,77.9063 68.0269,78.573 66.5,78.5C 66.8212,76.9519 67.8212,76.2852 69.5,76.5 Z"/></g>
<g><path style="opacity:0.051" fill="#fff3b3" d="M 69.5,76.5 C 67.8212,76.2852 66.8212,76.9519 66.5,78.5C 65.2089,77.4583 65.2089,76.2917 66.5,75C 67.9976,74.4153 68.9976,74.9153 69.5,76.5 Z"/></g>
<g><path style="opacity:1" fill="#e5af45" d="M 37.5,78.5 C 39.0418,79.3007 40.7085,79.634 42.5,79.5C 46.2639,80.8318 50.2639,81.6651 54.5,82C 48.8051,82.6258 43.1385,82.4591 37.5,81.5C 37.0472,80.5416 36.3805,79.7083 35.5,79C 36.0944,78.5357 36.7611,78.369 37.5,78.5 Z"/></g>
<g><path style="opacity:0.704" fill="#fbe297" d="M 42.5,79.5 C 46.8461,79.3343 51.1794,79.501 55.5,80C 56.727,81.5874 56.3937,82.9207 54.5,84C 48.4684,84.8709 42.8018,84.0375 37.5,81.5C 43.1385,82.4591 48.8051,82.6258 54.5,82C 50.2639,81.6651 46.2639,80.8318 42.5,79.5 Z"/></g>
<g><path style="opacity:1" fill="#f0ddb1" d="M 76.5,79.5 C 76.369,80.2389 76.5357,80.9056 77,81.5C 77.8159,80.1772 78.9826,79.5106 80.5,79.5C 74.2246,86.0956 66.5579,90.0956 57.5,91.5C 57.281,90.325 57.6143,89.325 58.5,88.5C 65.5368,87.3884 71.5368,84.3884 76.5,79.5 Z"/></g>
<g><path style="opacity:0.902" fill="#eddcb7" d="M 11.5,77.5 C 18.6914,87.0305 28.0247,93.3638 39.5,96.5C 38.4324,97.4345 37.099,97.7678 35.5,97.5C 24.9205,94.2524 16.2538,88.2524 9.5,79.5C 10.8333,79.5 11.5,78.8333 11.5,77.5 Z"/></g>
<g><path style="opacity:0.988" fill="#eedaba" d="M 83.5,83.5 C 83.3284,84.4916 83.6618,85.1583 84.5,85.5C 79.6254,90.3716 73.9587,94.0382 67.5,96.5C 67.1667,95.8333 66.8333,95.1667 66.5,94.5C 72.9745,92.0149 78.6412,88.3483 83.5,83.5 Z"/></g>
<g><path style="opacity:0.988" fill="#f0e6c9" d="M 66.5,94.5 C 66.8333,95.1667 67.1667,95.8333 67.5,96.5C 65.6287,97.7972 63.6287,98.7972 61.5,99.5C 53.8333,99.5 46.1667,99.5 38.5,99.5C 37.5,98.8333 36.5,98.1667 35.5,97.5C 37.099,97.7678 38.4324,97.4345 39.5,96.5C 48.6244,96.9713 57.6244,96.3047 66.5,94.5 Z"/></g>

`,
]
