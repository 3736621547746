<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <div class="logo-wrapper sidebar-brand-full">
        <div class="logo-wrapper__image">
          <CIcon
            custom-class-name="sidebar-brand-full"
            :icon="logoNegative"
            :height="35"
          />
        </div>
        <p class="logo-wrapper__text">{{APP_NAME}}</p>
      </div>

      <div class="logo-wrapper sidebar-brand-narrow d-none">
        <div class="logo-wrapper__image">
          <CIcon
            custom-class-name="sidebar-brand-narrow"
            :icon="sygnet"
            :height="35"
          />
        </div>
        <p class="logo-wrapper__text">{{ APP_NAME }}</p>
      </div>
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import Const from '@/plugins/constanst'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    const { APP_NAME } = Const
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      APP_NAME
    }
  },
}
</script>
<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  &__image {
    width: 27px;
    margin-right: 10px;
  }
}
</style>
