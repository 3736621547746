export default {
  install: (app) => {
    const useFetch = app._context.provides.useFetch

    const apiMethods = {
      get: async (url, options) => {
        return await useFetch(url, options)
      },
      post: async (url, options) => {
        return await useFetch(url, {
          method: 'post',
          ...options,
        })
      },
      put: async (url, options) => {
        return await useFetch(url, {
          method: 'put',
          ...options,
        })
      },
      patch: async (url, options) => {
        return await useFetch(url, {
          method: 'patch',
          ...options,
        })
      },
      delete: async (url, options) => {
        return await useFetch(url, {
          method: 'delete',
          ...options,
        })
      },
    }

    app.provide('apiMethods', apiMethods)
  },
}
