export default {
  install: (app) => {
    const isEmail = (email) => {
      if (!email) return false
      /* eslint-disable */
      return !!email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
      /* eslint-enable */
    }

    const isPhoneNumber = (phoneString) => {
      if (
        phoneString.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
      ) {
        return true
      } else {
        return false
      }
    }

    const formatDate = (date) => {
      if (!date) return
      date = new Date(date)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    }

    app.provide('utils', {
      isEmail,
      isPhoneNumber,
      formatDate,
    })
  },
}
