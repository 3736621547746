import { createApp } from 'vue'
import App from './App.vue'
import api from './plugins/api'
import apiMethods from '@/plugins/apiMethods'
import apiRepository from '@/plugins/apiRepository'
import RouteConstants from '@/plugins/RouteAsset'
import store from './store'
import router from './router'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import utils from '@/plugins/utils'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import Consts from '@/plugins/Consts'

const app = createApp(App)
app.use(RouteConstants)
app.use(Consts)
app.use(api, { router })
app.use(apiMethods)
app.use(apiRepository)
app.use(utils)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

app.mount('#app')
