import Cookie from 'cookie-universal'
import store from '@/store/index'
import RouteAsset from '@/plugins/RouteAsset'

const { ROLES, ROUTE_PATHS } = RouteAsset
const cookies = Cookie()

export default async function adminMasterAuth({ next, router }) {
  let token = cookies.get('token')

  if (token) {
    const isTokenExpire = await store.dispatch('checkTokenExpire')

    if (isTokenExpire) {
      await store.dispatch('refreshToken')
      token = cookies.get('token')
    }
  }

  const user = cookies.get('user')
  const roles = [ROLES.ADMIN, ROLES.MASTER]

  if (!token || !roles.includes(user.role)) {
    if (router.currentRoute.value.path == ROUTE_PATHS.LOGIN) {
      router.push(ROUTE_PATHS.PAGE_500)
      return next()
    } else {
      return router.replace(ROUTE_PATHS.LOGIN)
    }
  }

  await store.dispatch('login')

  return next()
}
