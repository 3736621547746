let RouteAsset = {}

const ROLES = {
  ADMIN: 'admin',
  MASTER: 'master',
  USER: 'user',
  SUPPORT: 'support',
}

const ROUTE_PATHS = {
  DASHBOARD: '/',
  USERS: '/pages/users',
  LOGIN: '/login',
  PAGE_500: '/pages/500',
  BANKS: '/pages/banks',
  STATISTICS: '/pages/Statistics',
  MODIFY_TRANSACTION: '/pages/ModifyTransaction/:id',
  MODIFY_WITHDRAW: '/pages/ModifyWithdraw/:id',
  WITHDRAWS: '/pages/Withdraws',
}

const ROLES_ASSET_PAGE = {
  DASHBOARD: [ROLES.ADMIN, ROLES.MASTER],
  USERS: [ROLES.MASTER],
}

const ROUTE_NAMES = {
  USERS: 'users',
  DASHBOARD: 'dashboard',
  BANKS: 'banks',
  STATISTICS: 'statistics',
  MODIFY_TRANSACTION: 'modify-transaction',
}

RouteAsset = {
  ROLES,
  ROUTE_PATHS,
  ROUTE_NAMES,
  ROLES_ASSET_PAGE,
}

export default RouteAsset
