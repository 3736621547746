import { createStore } from 'vuex'
import { useFetch } from '@vueuse/core/index'
import Cookie from 'cookie-universal'
import Const from '@/plugins/constanst'

const cookies = Cookie()
const { API_URL } = Const

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    authenticated: false,
    loading: false,
    user: null,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    AUTHENTICATE_USER(state, payload) {
      state.authenticated = payload.authenticated
      state.user = payload.user
    },
    LOGOUT(state) {
      state.authenticated = false
    },
    SET_AUTHENTICATE(state, status) {
      state.authenticated = status
    },
  },
  actions: {
    async authenticateUser({ commit }, { phone, otp, email }) {
      const isLoginEmail = !!email
      const apiUrl = isLoginEmail
        ? `${API_URL}/auth/email-otp`
        : `${API_URL}/auth/sms-otp`

      const { data } = await useFetch(apiUrl, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          phone,
          otp,
        }),
      })

      if (data.value) {
        const dataObject = JSON.parse(data.value)

        cookies.set('token', dataObject.accessToken)
        cookies.set('refreshToken', dataObject.refreshToken)
        cookies.set('user', dataObject.user)

        commit('AUTHENTICATE_USER', {
          user: dataObject.user,
          authenticated: true,
        })
      } else {
        cookies.set('token', null)
        cookies.set('refreshToken', null)
        cookies.set('user', null)

        commit('AUTHENTICATE_USER', {
          user: null,
          authenticated: false,
        })
      }
    },

    async checkTokenExpire() {
      const token = cookies.get('token')

      const { data } = await useFetch(`${API_URL}/users/me`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      })

      return !data.value
    },

    async refreshToken({ commit }) {
      const refreshToken = cookies.get('refreshToken')

      const { data } = await useFetch(`${API_URL}/refresh-token`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          refreshToken,
        }),
      })

      if (data.value) {
        const dataObject = JSON.parse(data.value)

        cookies.set('token', dataObject.accessToken)

        commit('SET_AUTHENTICATE', true)
      } else {
        cookies.set('token', null)
        commit('SET_AUTHENTICATE', false)
      }
    },

    logUserOut() {
      cookies.set('token', null)
      cookies.set('refreshToken', null)
      cookies.set('user', null)
    },
    login({ commit }) {
      commit('AUTHENTICATE_USER', {
        authenticated: true,
        user: cookies.get('user'),
      })
    },
  },
  modules: {},
})
