import images from '@/services/api/images'
import users from '@/services/api/users'
import transactions from '@/services/api/transactions'

export default {
  install: (app) => {
    const apiMethods = app._context.provides.apiMethods

    const repositories = {
      images: images(apiMethods),
      users: users(apiMethods),
      transactions: transactions(apiMethods),
    }

    app.provide('api', repositories)
  },
}
