export default {
  install: (app) => {
    app.provide('Consts', {
      TRANSACTION_STATUS: {
        CREATED: 'CREATED',
        TRANSFERED: 'TRANSFERED',
        PROCESSING: 'PROCESSING',
        CONFIRMED: 'CONFIRMED',
        MAILED: 'MAILED',
        HOLDON: 'HOLDON',
        DENIED: 'DENIED',
      },
      ERROR_MSG: {
        UPDATE_SUCCESS: 'Cập nhật dữ liệu thành công.',
        SOMETHING_WRONG:
          'Có lỗi xẩy ra trong quá trình xử lý vui lòng thử lại sau.',
      },
      TRANSACTION_ACTIONS: {
        PROCESS: 'PROCESS',
        CONFIRMED: 'CONFIRMED',
        HOLDON: 'HOLDON',
        DENIED: 'DENIED',
        MAILED: 'MAILED',
      },
    })
  },
}
