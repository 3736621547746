<template>
  <CFooter>
    <div>
      <a href="/" target="_blank">muabantraodoiUSDT</a>
      <span class="ms-1">&copy; {{ new Date().getFullYear() }}.</span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a :href="APP_PUBLIC_URL">{{ APP_PUBLIC_URL }}</a>
    </div>
  </CFooter>
</template>

<script>

import Const from '@/plugins/constanst'
export default {
  name: 'AppFooter',
  setup() {
    const { APP_PUBLIC_URL } = Const
    return {
      APP_PUBLIC_URL
    }
  }
}
</script>
