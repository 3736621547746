export default ($api) => ({
  show(id) {
    return $api.get(`/transactions/${id}`)
  },
  update(id, params) {
    return $api.put(`/transactions/${id}`, { body: params })
  },
  updateToMailed(id) {
    return $api.put(`/transactions/${id}/mail`)
  },
})
